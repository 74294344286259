.chatbot {
  .chat-bot-options {
    padding: 16px 0px;
    background-color: var(--panel);
    padding: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    p {
      color: var(--carbon);
    }

    button {
      width: 120px;
    }
  }

  .list {
    color: var(--panel-text);

    .list-row {
      padding: 0;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;

      p.small {
        color: #76838f;
        font-size: 10px;
        text-align: left;
        margin-bottom: 8px;

        &.text-center {
          text-align: center;
        }
      }

      .behavior {
        border: 1px solid #f4eeff;
        font-size: 12px;
        font-weight: bold;
        color: #404965;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        width: auto;

        i {
          margin: 0;
          margin-left: 8px;
        }

        span {
          text-align: left;
          margin: 0 8px;
          width: auto;
        }
      }

      .input-number {
        height: 35px;

        .controls {
          padding: 0 8px;
          height: 35px;
        }
      }

      .alias {
        height: 30px;
        width: 100%;
        font-size: 14px;
        line-height: 30px;
      }

      .order {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        background-color: var(--background);
        position: relative;

        i {
          font-size: 16px;
          position: absolute;
          right: -24px;
          background-color: white;
          padding: 8px;
          border-radius: 50%;
          border: 1px solid #f5f5f5;
        }
      }
    }
  }

  .command {
    .list {
      .list-header,
      .list-row {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .bot-option {
    border-radius: 5px;

    .remove {
      position: absolute;
      right: -8px;
      top: -4px;
      background-color: white;
      border: 1px solid lightgray;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  aside {
    overflow-y: auto;
  }
}

@media screen and (min-width: 1280px) {
  .chatbot {
    .list {
      .list-header {
        grid-template-columns: 80px 70px 1fr 120px 60px 100px 60px 300px;
        padding: 16px 0;

        &:nth-child(1) {
          padding-left: 4px;
        }
      }

      .list-row {
        grid-template-columns: 80px 70px 1fr 120px 60px 100px 60px 300px;

        .buttons-list-row {
          gap: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .chatbot {
    .list {
      .list-row {
        .order,
        .custom-option {
          padding: 0 16px;
        }

        .order {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .row {
            display: block;
          }
        }

        .row {
          i {
            position: inherit;
          }
        }

        .buttons-list-row {
          gap: 8px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .chatbot {
    .chat-bot-options {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .row {
        justify-content: flex-start;
        margin-top: 16px;
      }
    }
  }
}
