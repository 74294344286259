.company-info-container {
  .company-info {
    padding: 24px;
    border: 0.5px solid var(--light-blue);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .overview-conversations {
      .overview-sectors {
        .overview-sector {
          background: transparent !important;
          border: 1px solid var(--light-purple);
        }
      }
    }

    .overview-attendants {
      .company-info-wrapper {
        .overview-attendant-sector {
          background: transparent !important;
          border: 1px solid var(--light-purple);
        }
      }
    }
  }

  .btn-filter {
    background: transparent linear-gradient(90deg, var(--blue) 0%, var(--water-green) 100%) 0% 0% no-repeat padding-box;
    color: var(--white);
    transition: background 1.5s;

    &:hover {
      background: var(--dark-blue);
    }
  }
}

.store-manage {
  .filter {
    display: grid;
    grid-template-columns: 1fr 400px 150px 150px 120px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .list {
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .list-header,
    .list-row {
      grid-template-columns: 70px 80px repeat(1, minmax(250px, 1fr)) repeat(3, 100px) repeat(2, 150px) 160px;
      gap: 8px;
      border-radius: 0px;

      .buttons-list-row {
        .row {
          gap: 8px;
        }
      }
    }

    .list-row {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@media (max-width: 1440px) {
  .store-manage {
    .filter {
      grid-template-columns: 1fr repeat(3, 150px) 120px;
    }
  }
}

@media (max-width: 1200px) {
  .store-manage {
    .filter {
      grid-template-columns: repeat(2, 1fr);

      button {
        margin-top: 8px;
        grid-column: 1 / 3;
      }
    }

    .list {
      .list-header {
        display: none;
      }

      .list-row {
        grid-template-columns: 1fr;
        gap: 10px;

        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          text-align: left;
          align-items: center;

          &::before {
            content: attr(data-content);
            align-items: center;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .store-manage {
    .form-config {
      .row {
        flex-direction: row !important;
      }
    }

    .form-config-container {
      div {
        .row {
          flex-direction: column;
          padding-left: 0;

          .form-control {
            width: 100%;
          }
        }
      }

      .form-buttons {
        flex-direction: column;
        margin-top: 0;

        span,
        button {
          width: 100%;
          margin: 4px 0;
        }
      }
    }
  }
}