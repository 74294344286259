.profile-container {
  .form-buttons {
    gap: 8px;
  }

  .profile-data {
    .upload,
    .upload .placeholder,
    .upload .placeholder-content {
      border-radius: 50%;
      overflow: hidden;
      border: none;
    }

    .upload-button {
      padding: 8px;
      background-color: #e4e9f4;
      position: absolute;
      color: #76838f;
      z-index: 10;
      border-radius: 50%;
      font-size: 10pt;
      right: 0px;
      bottom: 0px;
    }

    .upload {
      border: 1px solid whitesmoke;
    }
  }

  .attendant-tab {
    width: 100%;
    display: grid;
    font-weight: bold;
    grid-template-columns: 1fr 2fr 1fr;

    .tab-option {
      padding: 16px;
      cursor: pointer;
      border-bottom: 2px solid gainsboro;

      &.active {
        border-bottom-color: var(--water-green);
      }
    }
  }
}

@media (max-width: 992px) {
  .profile-container {
    .profile-data,
    .attendant-data,
    .complementary-data {
      flex-direction: column;

      .row {
        flex: unset !important;

        .upload-button {
          bottom: 10px;
        }
      }
    }

    .complementary-data {
      .form-buttons {
        button,
        span {
          flex: 1;
        }
      }

      .col-6 {
        label {
          width: 100%;

          .checkmark {
            flex: unset;
            height: 22px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .profile-container {
    width: auto !important;
  }
}

@media (max-width: 600px) {
  .profile-container {
    .attendant-tab {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 480px) {
  .profile-container {
    .attendant-tab {
      align-items: flex-end;
      text-align: center;
    }

    .profile-data {
      .name-wrapper {
        flex-direction: column;

        .form-control {
          width: 100%;
        }
      }

      .form-buttons {
        span,
        button {
          width: 100%;
          margin: 4px 0;
        }
      }
    }

    .attendant-data {
      .password-wrapper {
        flex-direction: column;
      }

      .form-buttons {
        span,
        button {
          height: 100%;
        }
      }
    }
  }
}
