.filter-current-conversation {
  position: absolute;
  top: 0;
  right: 0px;
  margin-right: -424px;
  z-index: 5;
  padding: 8px;
  border-radius: 5px;
  width: 410px;
  background-color: white;

  .button {
    height: auto;
    margin-bottom: 8px;
    background-color: #f1f1f1;
    justify-content: space-between;
  }
}