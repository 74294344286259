.report-overview {
  .overview-item {
    padding: 16px;
    border-radius: 5px;
    background-color: rgb(240, 240, 240);

    h2 {
      text-align: center;
    }

    .overview-quantities {
      display: grid;
      grid-template-columns: repeat(5, auto);
      gap: 16px;
    }

    .overview-conversations {
      display: grid;
      grid-template-columns: 300px 1fr;
      grid-template-rows: 1fr 1fr;

      .chart {
        grid-row: 1 / 3;
      }

      .quantities {
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-row: 1;
        grid-column: 2 / 3;
        gap: 16px;
      }

      .overview-sectors {
        .overview-sector {
          width: auto;
          margin: 4px;
          padding: 8px;
          border-radius: 5px;
          background-color: gainsboro;
        }
      }
    }

    .overview-attendants {
      display: grid;
      margin-top: 8px;
      grid-template-columns: 48px 1fr 150px 2fr;

      img {
        border-radius: 5px;
        margin-right: 8px;
      }

      .overview-attendant-sector {
        width: auto;
        margin: 4px;
        background-color: gainsboro;
        padding: 8px;
        border-radius: 5px;
      }
    }
  }

  form {
    .search-attendance {
      border: 0.5px solid var(--light-blue);
      width: 100%;
      border-radius: 3px;

      .form-config {
        padding: 24px;
        background-color: var(--white-gainsboro);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    .button-times {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 22px;
      font-size: 24px;
      background-color: var(--dark-red);
      color: #ffffff;
    }

    .search-result-attendance {
      .list {
        .list-header {
          display: grid;
          grid-template-columns: repeat(6, minmax(5rem, 1fr));
          background-color: var(--white-alpha);
          border-radius: 0;
          color: var(--text-black);
        }

        .list-row {
          display: grid;
          grid-template-columns: repeat(6, minmax(5rem, 1fr));
          margin-bottom: 0;
          transition: background 1s;

          &:hover {
            background: var(--white-alpha);
          }

          div {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0 8px 0 0;
          }
        }
      }
    }
  }
}

.statistic-container {
  .row {
    h2 {
      flex: 1;
      color: var(--panel-text);
    }
  }

  form {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 300px) 60px;
    align-items: end;
    justify-content: flex-end;

    .form-control {
      width: 100%;

      label {
        color: var(--panel-text);
      }
    }
  }

  .chart-content {
    height: 300px;
    width: 400px;
  }
}

@media (max-width: 1280px) {
  .statistic-container {
    .grid-2 {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 992px) {
  .statistic-container {
    form {
      grid-template-columns: repeat(2, 1fr) 60px;
    }
  }
}

@media (max-width: 768px) {
  .statistic-container {
    .grid-2 {
      grid-template-columns: 1fr;
      .chart-content {
        width: auto !important;
      }
    }

    .grid-4 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 600px) {
  .statistic-container {
    .statistic-date {
      flex-direction: column;
    }
  }
}

@media (max-width: 414px) {
  .statistic-container {
    .grid-4 {
      grid-template-columns: 1fr;
    }
  }
}
