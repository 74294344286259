.plan-container {
  .modal-body {
    .buttons {
      display: grid;
      grid-template-columns: 1fr 250px;
      gap: 20px;
    }
  }

  .list {

    .list-header,
    .list-row {
      grid-template-columns: 50px 1fr repeat(3, 80px) 60px 100px repeat(2, 130px) 160px;
      gap: 10px;

      .buttons-list-row {
        .row {
          gap: 4px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .plan-container {
    .list {
      .list-header {
        display: none;
      }

      .list-row {
        grid-template-columns: 1fr;
        gap: 10px;

        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          text-align: left;
          align-items: center;

          &::before {
            content: attr(data-content);
            align-items: center;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .plan-container {
    .modal-body {
      .buttons {
        grid-template-columns: 1fr;

        .row {

          span,
          button {
            flex: 1;
          }
        }
      }
    }
  }
}