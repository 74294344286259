.alert {
  position: fixed;
  width: 350px;
  z-index: 15;
  display: none;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  right: 0;
  top: 0;

  &.animated {
    display: flex;
  }

  .content {
    border-radius: 5px;
    padding: 16px;
    width: 100%;
    display: none;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    grid-template-columns: 30px 1fr;
    gap: 8px;

    &[data-status="success"] {
      border-left: 5px solid #4cb748;
      color: #4cb748;
      background-color: #f6fff5;
    }

    &[data-status="default"] {
      border-left: 5px solid #464646;
      color: #1f1f1f;
      background-color: whitesmoke;
    }

    &[data-status="danger"] {
      border-left: 5px solid #f80031;
      color: #f80031;
      background-color: #fdd1da;
    }

    &[data-status="warning"] {
      border-left: 5px solid #ff3709;
      color: #ff3709;
      background-color: #fee6e0;
    }

    &.animated {
      display: grid;
    }

    .icon {
      font-size: 30px;
      margin: 0;
    }

    h1,
    h4 {
      padding: 0;
      margin: 0px;
      color: #1f1f1f;
      text-align: left;
      justify-content: center;
    }

    h1 {
      font-size: 22pt;
    }

    h4 {
      margin-top: -4px;
    }

    .close-btn {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
}

@media screen and (min-width: 175px) and (max-width: 1023px) {
  .alert {
    .content {
      width: 90%;

      h1 {
        font-size: 20pt;
      }
    }
  }
}
