.row-list {
  width: 100%;
  display: grid;
  position: relative;
}

.list {
  @extend .row-list;
  overflow-x: auto;

  .list-header {
    @extend .row-list;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 16px;
    display: grid;
    background-color: var(--white-alpha);
    color: var(--text-black);
    font-weight: 600;
    gap: 16px;

    z-index: 1;

    div {
      text-align: left;
      justify-content: flex-start;
    }
  }

  .list-row {
    @extend .row-list;
    background-color: var(--panel);
    min-height: 50px;
    padding: 8px 16px;
    margin-bottom: 8px;
    align-content: center;
    align-items: center;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
    display: grid;
    margin-bottom: 0;
    transition: background 1s;
    gap: 16px;

    p {
      color: var(--panel-text);
    }

    .status {
      padding: 10px;
      height: 38px;
      width: 100%;
      color: white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 10pt;
      line-height: 10pt;

      &.success,
      &[data-status="CONFIRMED"],
      &[data-status="ACTIVE"] {
        color: var(--green);
        background-color: var(--green-alpha);
      }

      &.danger,
      &[data-status="REJECTED"],
      &[data-status="CANCELLED"] {
        color: #f80031;
        background-color: #fdd1da;
      }

      &.warning,
      &[data-status="REFUNDED"],
      &[data-status="EXPIRED"],
      &[data-status="FINISHED"] {
        color: #ff3709;
        background-color: #ffebc7;
      }

      &.secondary,
      &[data-status="PENDING"],
      &[data-status="INACTIVE"] {
        color: gray;
        background-color: whitesmoke;
      }

      &.default,
      &[data-status="PROCESSING"] {
        color: #fff;
        background-color: #4172c6;
      }

      &.info {
        color: #0091ff;
        background-color: #a6d9ff;
      }
    }

    &:hover {
      background: var(--white-alpha);
    }

    &[data-active="true"] {
      background-color: rgba(0, 0, 0, 0.06);
    }

    div {
      justify-content: flex-start;
    }

    .buttons-list-row {
      .row {
        gap: 4px;
      }

      a,
      span,
      button {
        border: 1px solid var(--light-purple);
        width: auto;
        height: 30px;
        border-radius: 5px;
        background: var(--white);
        padding: 8px;
        color: var(--panel-text);

        i {
          margin: 0;
        }
      }
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 70svh;

      >div {
        &::before {
          display: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .list {
    .list-header {
      display: none;
    }

    .list-row {
      grid-template-columns: 1fr;

      >div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: left;
        align-items: center;

        &::before {
          content: attr(data-content);
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .list {

    .list-row {
      background-color: var(--panel);

      .row {
        a,
        button,
        span {
          width: 100%;
        }
      }
    }
  }
}