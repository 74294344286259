.redirect-requests {
  .redirect-tab {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: bold;

    .tab-option {
      padding: 16px;
      cursor: pointer;
      border-bottom: 2px solid gainsboro;

      &.active {
        border-bottom-color: var(--water-green);
      }
    }
  }

  .panel-body {
    i {
      font-size: 16px !important;
    }
  }

  .button {
    height: 36px;
  }
}

@media (max-width: 768px) {
  .redirect-requests {
    width: auto !important;
  }
}

@media (max-width: 600px) {
  .redirect-requests {
    .redirect-tab {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 480px) {
  .redirect-requests {
    .redirect-tab {
      text-align: center;
      align-items: flex-end;
    }
  }
}
