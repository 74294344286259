.attendants-container {
  .modal-body {
    .panel {
      .form-buttons {
        gap: 8px;

        span {
          width: 120px;
        }
      }
    }
  }

  .gradient {
    width: 120px;
  }

  .attendant-data {

    .upload,
    .upload .placeholder,
    .upload .placeholder-content {
      border-radius: 50%;
      overflow: hidden;
      border: none;
    }

    .upload-button {
      padding: 8px;
      background-color: #e4e9f4;
      position: absolute;
      color: #76838f;
      z-index: 10;
      border-radius: 50%;
      font-size: 10pt;
      right: 0px;
      bottom: 0px;
    }

    .upload {
      border: 1px solid whitesmoke;
    }
  }

  .attendant-tab {
    overflow-x: auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    font-weight: bold;

    .tab-option {
      padding: 16px;
      cursor: pointer;
      border-bottom: 2px solid gainsboro;

      &.active {
        border-bottom-color: var(--water-green);
      }
    }
  }

  .filter {
    display: grid;
    grid-template-columns: 2fr 1fr 150px 120px;
    border-radius: 0px;
    border: 0px;
    gap: 8px;
  }
}

@media screen and (min-width: 1280px) {
  .attendants-container {
    .list {
      .list-header {
        grid-template-columns: 50px 1fr 1fr 130px 100px 160px;
      }

      .list-row {
        grid-template-columns: 50px 1fr 1fr 130px 100px 160px;

        .buttons-list-row {
          .row {
            gap: 4px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .attendants-container {
    .attendant-tab {
      grid-template-columns: 1fr;
    }

    .modal-body {
      .panel {
        .form-buttons {
          gap: 8px;

          span {
            width: 100%;
          }
        }
      }
    }
  }

  .filter {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 175px) and (max-width: 1023px) {
  .attendants-container {
    .modal-body {
      .panel {
        width: 100%;
      }
    }
  }
}