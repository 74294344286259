.sign-container {
  display: flex;
  background-color: var(--dark-blue);

  .sign-content {
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;

    input, select {
      border-left: 1px solid var(--input-border);
    }

    .sign-title-box {
      img {
        width: 200px;
      }
      h2,
      p {
        color: white;
        margin-top: 16px;
      }
    }
  }

  form {
    background-color: var(--white);
    padding: 30px;
    border-radius: 10px;
    margin: 24px 0;

    h2,
    p,
    label,
    a {
      color: var(--carbon);
    }

    .form-control {
      label {
        font-weight: bold;
      }
      .icon-widget {
        width: 48px;
        height: 48px;
        border: 1px solid var(--gray);
        border-right: none;
        padding: 14.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        i {
          font-size: 18px;
        }
      }

      input, select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .recovery-label {
        width: 150px;
        font-size: 14px;
        text-align: right;
      }

      .button-entry {
        background: transparent linear-gradient(90deg, var(--blue) 0%, var(--water-green) 100%) 0% 0% no-repeat
          padding-box;
        color: var(--white);
        border-radius: 5px;
        height: 48px;
        transition: background 1.5s;

        &:hover {
          background: transparent linear-gradient(90deg, var(--dark-blue) 0%, var(--dark-blue) 100%) 0% 0% no-repeat
            padding-box;
        }
      }

      .version-label {
        opacity: 0.5px;
        margin-top: 8px;
        font-size: 12px;
        color: var(--dark-blue);
      }
    }
  }

  .underchat-copy-label {
    color: var(--light-blue);
  }
}

@media (max-width: 375px) {
  .sign-container {
    .sign-content {
      form {
        .icon-widget {
          width: 40px;
        }

        input:nth-child(2n) {
          width: 150px;
        }
      }
    }
  }
}
