.invoice-container {
  .modal-body {
    .buttons {
      gap: 8px;
    }
  }

  .plan-tile {
    display: grid;
    grid-template-columns: 66px 1fr 186px;
    gap: 16px;
    height: 120px;
    border-radius: 8px;
    margin-bottom: 8px;
    position: relative;
    cursor: pointer;
    align-content: center;

    > .best-offer {
      color: white;
      position: absolute;
      top: 0;
      left: 32px;
      padding: 4px 12px;
      border-radius: 0px 0px 10px 10px;
      background: transparent
        linear-gradient(
          90deg,
          rgba(3, 105, 209, 1) 0%,
          rgba(0, 236, 188, 1) 100%
        )
        0% 0% no-repeat padding-box;
    }

    > .icon {
      fill: var(--highlight);
      background-color: var(--secondary);
      padding: 12px;
      border-radius: 50%;
      margin-left: 16px;
      height: 50px;
      width: 50px;
    }

    > .info .title {
      font-size: 23px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--primary);
    }

    > .info .description {
      opacity: 0.6;
    }

    > .price {
      width: 170px;
      height: 80px;
      padding: 16px;
      color: gray;
      text-align: center;
      border-radius: 10px;
      background-color: whitesmoke;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
      margin-right: 16px;
      display: grid;
      align-content: center;

      > .main {
        font-size: 18px;

        > b {
          font-size: 2rem;
          color: var(--highlight);
        }
      }

      > .old {
        font-size: 14px;
        text-decoration: line-through;
      }
    }

    &[data-active='true'] {
      border-radius: 16px;
      background-image: linear-gradient(white, white),
        linear-gradient(to right, rgb(3, 105, 209), rgb(0, 236, 188));
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 3px;

      .price {
        color: rgba(255, 255, 255, 0.7);
        background: transparent
          linear-gradient(90deg, rgb(3, 105, 209) 0%, rgb(0, 236, 188) 100%) 0%
          0% no-repeat padding-box;

        > .main > b {
          color: white;
        }
      }
    }

    &[data-active='false'] {
      border-radius: 16px;
      background-image: linear-gradient(white, white),
        linear-gradient(to right, rgb(209, 209, 209), rgb(233, 233, 233));
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 3px;
    }
  }

  .payment-tile {
    padding: 3px;
    display: grid;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    margin-top: 8px;
    border-radius: 8px;
    align-content: center;
    grid-template-columns: 66px 1fr;

    > .radio {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: gainsboro;
      margin-left: 16px;
    }

    &[data-active='true'] {
      background-origin: border-box;
      background-clip: content-box, border-box;
      background-image: linear-gradient(white, white),
        linear-gradient(to right, rgb(3, 105, 209), rgb(0, 236, 188));

      > .radio {
        background: transparent
          linear-gradient(
            90deg,
            rgba(3, 105, 209, 1) 0%,
            rgba(0, 236, 188, 1) 100%
          )
          0% 0% no-repeat padding-box;
      }
    }

    &[data-active='false'] {
      background-origin: border-box;
      background-clip: content-box, border-box;
      background-image: linear-gradient(white, white),
        linear-gradient(to right, rgb(209, 209, 209), rgb(233, 233, 233));
    }

    > .name {
      padding: 12px 0;

      > .method {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      > .type {
        margin: 0;
      }
    }
  }

  .payment-asaas-pix {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto 1fr;
    justify-items: center;
    gap: 16px;

    > .logo {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      width: 300px;
      margin-bottom: 32px;
    }

    > .qrcode {
      width: 100%;
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    > .description {
      width: 100%;
      height: 100%;
      display: flex;
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      flex-flow: row wrap;
      align-content: flex-start;
      justify-content: flex-start;
      background-color: #efefef;
      position: relative;
      border-radius: 10px;
      padding: 24px;

      > ol {
        font-size: 16px;
        padding-left: 16px;
      }

      > .button.remove {
        position: absolute;
        right: 16px;
        top: 16px;
        color: firebrick;
        border-color: firebrick;
        opacity: 0.6;
      }
    }
  }

  .modal {
    .list {
      .list-header,
      .list-row {
        grid-template-columns: 40px 100px 1fr 180px;
      }
    }
  }

  .custom-payment-wrapper {
    .list {
      .list-header, .list-row {
        grid-template-columns: 100px 1fr 1fr 200px;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .invoice-container {
    .list {
      .list-header {
        grid-template-columns: 60px 100px 100px 1fr 1fr 130px 130px 100px;
      }

      .list-row {
        grid-template-columns: 60px 100px 100px 1fr 1fr 130px 130px 100px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .invoice-container {
    .modal-body {
      .plan-tile {
        grid-template-columns: 1fr;
        height: auto;

        .price {
          width: 100%;
        }

        .icon {
          height: 60px;
          width: 60px;
          margin-top: 30px;
        }
      }
    }

    .custom-payment-wrapper {
      .list {
        .list-header {
          display: none;
        }
    
        .list-row {
          grid-template-columns: 1fr;
    
          >div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            text-align: left;
            align-items: center;
    
            &::before {
              content: attr(data-content);
              align-items: center;
            }
          }
        }
      }
    }
  }
}
