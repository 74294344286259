.modal-box {
  display: none;
  position: fixed;
  z-index: 10;
  border-radius: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  &.animated {
    display: flex;
  }

  .modal-body {
    display: none;
    flex-flow: column;
    position: absolute;
    color: white;
    margin: 0 auto;
    right: 0;
    left: 0;
    top: 0;
    margin-top: 20px;
    width: 750px;

    .hero {
      background-color: var(--background);
    }

    .panel, .panel-body {
      max-height: 94vh!important;
    }

    .panel {
      overflow: auto;
    }

    .close {
      position: absolute;
      top: 0;
      right: -64px;
      color: white;
      font-size: 32pt;
    }

    &.animated {
      display: block;
    }

    h1 {
      font-size: 27pt;
      padding: 0;
      margin: 8px;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  .modal-bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@media screen and (min-width: 175px) and (max-width: 1280px) {
  .modal-box {
    .modal-body {
      margin: 20px;
      width: auto !important;

      h1 {
        font-size: 20pt;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .modal-box {
      .modal-body {
        .panel {
          .buttons,
          .panel-body-button,
          .form-buttons {
            flex-direction: column;
            gap: 8px;

            span,
            button {
              flex: 1;
              width: 100%;
              margin: 0px;
            }
          }
        }

        .hero {
          flex-direction: column;
          align-items: flex-start;

          .row {
            gap: 8px;
            margin-top: 16px;
          }

          .span {
            width: 100%;
            margin: 0px;
          }
        }

        .button, button {
          width: 100%;
        }
      }
    }
  }
}
