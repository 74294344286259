.dashboard-stores {
  .header-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr) repeat(2, 100px);
  }

  h1,
  h2 {
    color: var(--carbon);
  }

  .card {
    h1 {
      font-size: 40px;
    }
  }

  .channel-limit {
    padding: 8px 16px;
    background-color: var(--highlight);
    color: white;
    width: auto;
    border-radius: 20px;
  }

  .date {
    padding: 8px 16px;
    background-color: var(--white);
    width: auto;
    border-radius: 20px;

    i {
      margin: 0;
      margin-right: 8px;
    }
  }

  .panel {
    border-radius: 10px;
  }

  .attendant {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: start;
    width: 100%;
    align-items: center;
    gap: 8px;

    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }

    p {
      margin: 0;
    }

    i.btn {
      color: var(--highlight);
      background-color: #f9f6f6;
      padding: 24px;
      border-radius: 50%;
      font-size: 50px;
    }

    h1 {
      font-size: 60px;
      height: 70px;
    }

    .sector {
      padding: 8px 16px;
      background-color: var(--highlight);
      color: white;
      width: auto;
      border-radius: 20px;
      margin-right: 8px;
    }
  }
}

@media (max-width: 1024px) {
  .dashboard-stores {
    .dashboard-stores-header {
      flex-direction: column;

      div {
        width: 100%;
        padding: 8px 0 0 0;

        .header-wrapper {
          display: grid;
          grid-template-columns: 200px 1fr repeat(2, 100px);

          .col-3 {
            width: 100%;
          }
        }
      }
    }

    .grid-2 {
      grid-template-columns: 1fr;
    }

    .grid-4 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 600px) {
  .dashboard-stores {
    .card {
      width: 100% !important;
    }

    .header-wrapper {
      grid-template-columns: 1fr !important;

      .date {
        padding: 12px 0px;
      }
    }

    .attendant {
      grid-template-columns: 1fr !important;
    }
  }
}

@media (max-width: 480px) {
  .dashboard-stores {
    .dashboard-stores-header {
      flex-direction: column;

      div {
        .header-wrapper {
          grid-template-columns: 1fr;
        }
      }
    }

    .grid-2 {
      .row {
        width: 100% !important;
      }
    }

    .grid-4 {
      grid-template-columns: 1fr;
    }
  }
}
