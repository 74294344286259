.tag-input {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  min-height: 48px;
  height: auto;
  flex-wrap: wrap;

  .row.select {
    margin: 4px;
  }

  .tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid gainsboro;
    margin: 4px;
    height: 48px;

    i {
      cursor: pointer;
    }
  }
}