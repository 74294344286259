.channels-container {
  .channel-header-buttons {
    width: auto;
    align-items: flex-end;
  }

  .qr-code-expired {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 16px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid gainsboro;
    border-radius: 5px;

    p {
      font-weight: bold;
      font-size: 14pt;
    }

    .button {
      margin-top: 8px;
    }
  }
}

.form-config-container {
  .panel-body {
    .buttons {
      gap: 8px;
    }
  }
}

@media (min-width: 1280px) {
  .channels-container {
    .list {
      .list-header {
        grid-template-columns: 50px 100px 1fr 1fr 130px 130px 290px;
      }

      .list-row {
        grid-template-columns: 50px 100px 1fr 1fr 130px 130px 290px;

        .buttons-list-row {
          .row {
            gap: 4px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .channels-container {
    .modal-body {
      .form-config-container {
        width: 100% !important;

        .buttons {
          gap: 8px;

          .button {
            flex: 1;
            margin-left: 0px;
          }
        }
      }
    }

    .channel-header-buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      width: 100% !important;

      .gradient {
        width: auto !important;
      }

      .form-control {
        width: auto !important;
      }
    }
  }
}