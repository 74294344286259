.badge {
  text-align: center;
  padding: 8px 16px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    margin-left: 0!important;
  }

  &.secondary, &[data-status='PENDING'], &[data-status='FETCHING'] {
    color: gray;
    background-color: whitesmoke;
  }

  &.danger, &[data-status='ERROR'], &[data-active='false'] {
    color: #f80031;
    background-color: #fdd1da;
  }

  &.warning, &[data-status='WARNING'] {
    color: #ff3709;
    background-color: #ffebc7;
  }

  &.default {
    color: #fff;
    background-color: #4172c6;
  }

  &.success, &[data-status='SUCCESS'], &[data-active='true'] {
    color: #1aae6f;
    background-color: #b0eed3;
  }

  &.info {
    color: #0091FF;
    background-color: #A6D9FF;
  }
}

.badge-pill {
  text-align: center;
  padding: 2px 8px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;

  &.secondary {
    color: gray;
    background-color: whitesmoke;
  }

  &.danger, &[data-active='false'] {
    color: #f80031;
    background-color: #fdd1da;
  }

  &.warning {
    color: #ff3709;
    background-color: #ffebc7;
  }

  &.default, &[data-active='undefined'] {
    color: #fff;
    background-color: #4172c6;
  }

  &.success, &[data-active='true'] {
    color: #1aae6f;
    background-color: #b0eed3;
  }

  &.info {
    color: #0091FF;
    background-color: #A6D9FF;
  }
}