ul.menu {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0;
  width: 100%;

  .quantity {
    top: 16px;
    margin-right: 8px;
  }

  li {
    width: 100%;
    margin: 0;

    &.menu-label {
      font-size: 12px;
      padding: 0;
      color: rgba(255, 255, 255, 0.7);
      padding-left: 16px;
    }

    a,
    span {
      cursor: pointer;
      color: #6764B4;
      display: flex;
      justify-content: flex-start;
      padding: 12px;
      font-size: 14px;
      margin-top: 4px;

      svg {
        width: 25px;
        height: 25px;
        margin-right: 8px;

        path {
          fill: #6764B4;
        }
      }

      ul {
        display: none;
      }

      &.header {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &:hover,
      &.active {
        background: #FFFFFF1A;
        color: white;
        border-radius: 5px;

        svg path {
          fill: var(--highlight);
        }
        ul {
          display: flex;
          padding: 0;
          padding-top: 8px;
        }
      }
    }
  }
}
