.upload {
  align-self: stretch;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);

  .upload-preview {
    align-self: stretch;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .clear-img {
      color: white;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
    }

    &:hover .clear-img {
      opacity: 1;
    }
  }
  .placeholder {
    height: 100%;
    display: flex;
    border-radius: 5px;
    padding: 12px;
    background-color: whitesmoke;
    background-color: var(--panel-1);
    align-self: stretch;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }

    .placeholder-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 12px;
      border-radius: 5px;
      height: 100%;
      width: 100%;
      word-break: break-word;
      border: 2px dashed gainsboro;

      svg {
        fill: gray;
      }
      p {
        font-size: 10pt;
        color: gray;
      }
    }
  }
}
