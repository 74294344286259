.conversation {
  display: grid;
  grid-template-columns: 340px 1fr;
  gap: 16px;
  align-items: stretch;
  position: relative;
  padding: 16px 16px 16px 0;

  .contact-info-right-modal {
    position: fixed;

    .panel-body {
      border: none;
    }

    .upload,
    .upload .placeholder .placeholder-content {
      overflow: hidden;
    }

    .upload-remove-btn {
      padding: 8px;
      background-color: #e4e9f4;
      position: absolute;
      color: #76838f;
      z-index: 10;
      border-radius: 50%;
      font-size: 10pt;
      left: -8px;
      bottom: -8px;
    }

    .form-item {
      display: grid;
      min-height: 48px;
      justify-items: end;
      align-content: center;
      grid-template-columns: 1fr;

      label {
        color: gray;
      }
    }
  }

  .conversation-sidebar {
    border-radius: 5px;
    height: calc(100vh - 102px);

    .redirect-btn {
      width: 48px;

      >i {
        font-size: 22px;
        color: var(--highlight);
      }
    }

    .conversation-buttons {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .sidebar-item {
        height: 64px;
        width: 64px;
        background-color: var(--panel);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        position: relative;
        cursor: pointer;

        i {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: #e4e9f4;
          color: var(--highlight);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          font-size: 9px;
          text-align: center;
        }

        .qtd {
          z-index: 2;
          color: white;
          background-color: red;
          border-radius: 20px;
          padding: 4px 8px;
          font-size: 10px;
          text-align: center;
          position: absolute;
          right: -8px;
          top: -8px;
        }

        &.active {
          background-color: var(--highlight);

          color: white;

          i {
            background-color: white;
          }
        }
      }

      .modal-body {
        width: 500px;
      }
    }

    .sidebar-content {
      height: calc(100vh - 280px);
      overflow: auto;
      padding: 16px 0;
      padding-right: 8px;

      .conversation-tile {
        margin-bottom: 12px;

        .tile-header {
          height: 18px;
          font-size: 12px;

          .channel,
          .tag {
            padding: 4px 8px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          .channel {
            color: gray;
            background-color: #d4dae9;
            max-width: 100px;
            margin-right: 4px;
          }

          .tag {
            color: white;
            max-width: 100px;
            margin-right: 4px;
          }
        }

        .tile-body {
          background-color: var(--panel);
          border-radius: 5px;
          border-top-left-radius: 0px;
          padding: 16px;
          padding-bottom: 21px;

          .counter {
            width: 17px;
            height: 17px;

            color: #ec0000;
            background-color: #fbc4c4;
            font-size: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .tile-footer {
          font-size: 12px;
          background-color: #d4dae9;
          padding: 4px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-top: -5px;
          color: #1f1f1f;
        }
      }
    }
  }

  .search-conversation {

    .list-header,
    .list-row {
      grid-template-columns: 150px 1fr 100px 100px 180px 48px;
    }
  }

  .attendant-join-box {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    width: calc(100% - 356px);
    height: 100%;
    z-index: 5;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 24px;
    text-align: center;

    &.admin {
      height: 100px;
      border-top: 1px solid gainsboro;

      >.row {
        width: auto;
        flex-flow: row;

        button {
          width: 200px;
        }
      }
    }
  }

  .conversation-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 90px 1fr auto;
    border-radius: 5px;
    border: 1px solid var(--border-gray);
    background-color: var(--light-blue);

    .header {
      display: grid;
      grid-row: 1 / 1;
      grid-template-columns: 350px 1fr 363px;
      background-color: var(--light);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 10px 20px;
      height: 100%;
      gap: 8px;
      align-content: center;
      align-items: center;

      .ellipse-wrapper {
        display: none;
      }

      .header-buttons-wrapper {
        display: none;
      }

      .header-center {
        border-left: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        padding: 0 12px;
        align-content: center;
        gap: 8px;
        color: var(--panel-text);

        .header-info {
          i {
            margin: 0;
          }

          strong {
            font-size: 14px;
          }

          p {
            font-size: 10px;
          }
        }

        .button:last-child {
          width: 48px;

          .quantity {
            top: -4px;
            right: -4px;
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .header-buttons {
        justify-content: flex-end;
        gap: 8px;

        .button {
          width: 48px;
          background-color: var(--white);

          &:last-child {
            width: auto !important;
          }
        }
      }
    }

    .footer {
      padding: 16px;
      height: auto;
      position: relative;

      .emoji-box {
        position: absolute;
        bottom: 80px;
        z-index: 999;
      }

      .button {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        padding: 0;
        bottom: 22px;

        i {
          color: #76838f;
          padding: 0;
          margin: 0;
        }

        &.emoji {
          left: 24px;
        }

        &.send {
          right: 24px;

          i {
            color: var(--highlight);
          }
        }

        &.audio-record-button {
          right: 62px;
          height: 40px !important;
          width: 40px !important;

          &.recording {
            width: 148px !important;

            i.fa-times-circle {
              margin-right: 8px;
              font-size: 18px;
            }

            i.fa-check-circle {
              margin-left: 8px;
              font-size: 18px;
            }

            i.fa-microphone {
              margin-left: 4px;
              margin-right: 8px;
            }
          }
        }
      }

      .attachment-new-item {
        display: flex;
        align-items: center;
        align-content: center;
        border-radius: 5px;
        background-color: #f1f1f1;
        color: #525252;
        cursor: pointer;
      }

      .attachment-item {
        display: flex;
        align-items: center;
        align-content: center;
        border-radius: 5px;
        background-color: #f1f1f1;
        color: #525252;
        background-color: #f1f1f1;

        .close-attachment {
          display: none;
          width: 100%;
          height: 100%;
          padding: 8px;
          position: absolute;
          background: rgb(0, 0, 0);
          justify-content: flex-end;
          background: linear-gradient(180deg,
              rgb(49, 46, 46) 0%,
              rgba(49, 46, 46, 0.2819502801120448) 22%,
              rgba(49, 46, 46, 0) 100%);

          i.fa {
            cursor: pointer;
            padding-left: 4px;
            width: 16px;
            height: 16px;
            color: white;
            display: flex;
            margin: 0;
          }
        }

        &:hover .close-attachment {
          display: flex;
        }
      }

      .attachments {
        width: 40px;
        height: 40px;
        left: 70px;
        position: absolute;
        bottom: 22px;

        .file,
        .picture,
        .video,
        .audio,
        .location,
        .contact,
        .main {
          bottom: 0;
          transition: transform 0.2s ease-in-out;
          border: 1px solid #e4e9f4;
          box-shadow: none;
        }

        &:hover {
          height: 310px;

          .file,
          .picture,
          .audio,
          .video,
          .location,
          .contact {
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);

            &:hover {
              background-color: var(--highlight);

              i {
                color: white;
              }
            }

            &::before {
              content: ' ';
              color: white;
              background-color: #404965;
              display: flex;
              padding: 4px 8px;
              border-radius: 22px;
              left: 46px;
              position: absolute;
              width: 80px;
              justify-content: center;
            }
          }

          .picture {
            transform: translateY(-46px);

            &::before {
              content: 'Imagem';
            }
          }

          .file {
            transform: translateY(-92px);

            &::before {
              content: 'Arquivos';
            }
          }

          .video {
            transform: translateY(-138px);

            &::before {
              content: 'Vídeo';
            }
          }

          .audio {
            transform: translateY(-182px);

            &::before {
              content: 'Áudio';
            }
          }

          .location {
            transform: translateY(-226px);

            &::before {
              content: 'Localização';
            }
          }

          .contact {
            transform: translateY(-270px);

            &::before {
              content: 'Contato';
            }
          }
        }
      }

      .button.attendants {
        position: absolute;
        left: 16px;
        top: -20px;
        background-color: #f4f6fc;
        width: 30px;
        height: 30px;

        i {
          font-size: 12px;
        }

        .quantity {
          top: -4px;
          right: -4px;
          font-size: 9px;
          height: 15px;
          width: 15px;
        }

        &:hover {
          opacity: 0.4;
        }
      }

      .shortcut {
        &.simple-table {
          .row {
            cursor: pointer;

            &:hover {
              background-color: rgba(0, 0, 0, 0.02);
            }
          }
        }
      }

      textarea {
        resize: none;
        padding: 8px;
        padding-left: 103px;
        padding-right: 90px;
        background-color: var(--light);
        border: 8px solid #e4e9f4;
        height: 55px;
        min-height: 55px;
        max-height: 122px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .preview {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      color: #1f1f1f;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }

      button {
        width: 300px;
      }

      .btn-close {
        font-size: 20pt;
        position: fixed;
        right: 32px;
        top: 32px;
        cursor: pointer;
        color: #1f1f1f;
      }

      .btn-open {
        font-size: 16pt;
        position: fixed;
        right: 70px;
        top: 32px;
        cursor: pointer;
        color: #1f1f1f;
      }

      img,
      video {
        height: 70vh;
        border-radius: 5px;
      }
    }

    .messages {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      padding: 8px;
      height: calc(100vh - 280px);
      justify-content: flex-start;
      background-color: var(--background);
      background-image: url('~assets/img/pattern-05.png');

      .message-box {
        padding-left: 25px;
        margin: 8px 0;
        user-select: none;

        .btn-reply {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          position: absolute;
          color: gray;
          visibility: hidden;
        }

        &:hover {
          .btn-reply {
            visibility: visible;
          }
        }

        &.left {
          justify-content: flex-start;

          .message-tile {
            grid-template-columns: 36px 1fr;

            .user-pic {
              grid-column: 1 / 2;
              grid-row: 1;
            }

            .flex {
              margin-left: 8px;
              grid-column: 2 / 3;
              grid-row: 1;
            }

            .item {
              color: #404965;
              background-color: #e5fffa;

              .message-footer {
                color: #404965;
                justify-content: flex-end;

                font-size: 12px;
              }

              &::after {
                left: -10px;
                border-left-color: transparent;
                border-bottom-color: transparent;
              }

              .message-content {
                a.attachment-file {
                  flex-flow: row wrap;
                  color: #646464;
                  font-weight: bold;
                }
              }
            }
          }

          .btn-reply {
            right: -58px;
          }
        }

        &.right {
          justify-content: flex-end;

          .message-tile {
            grid-template-columns: 1fr 36px;

            .user-pic {
              grid-column: 2 / 3;
              grid-row: 1;
            }

            .flex {
              margin-right: 8px;
              grid-column: 1 / 2;
              grid-row: 1;
            }

            .item {
              background-color: #f5f7fb;

              .message-footer {
                color: #7a7f9a;
                justify-content: flex-start;

                font-size: 12px;
              }

              &::after {
                right: -10px;
                border-color: #f5f7fb;
                border-right-color: transparent;
                border-top-color: transparent;
              }

              .message-content {
                a.attachment-file {
                  flex-flow: row wrap;
                  font-weight: bold;
                }
              }
            }

            .reply {
              color: rgba(0, 0, 0, 0.9);
            }

            .message-footer {
              justify-content: flex-end;
            }
          }

          .btn-reply {
            left: -70px;
          }
        }

        .message-tile {
          width: auto;
          border-radius: 5px;
          padding: 8px;
          user-select: text;
          align-items: flex-end;
          display: grid;

          .sender-name {
            width: auto;
            margin: 12px;
            font-size: 10px;
            color: #76838f;
          }

          .remove-button {
            opacity: 0.3;
            font-size: 9pt;
            cursor: pointer;
            margin: 12px;

            &:hover {
              opacity: 1;
            }
          }

          .reply {
            width: auto;
            padding: 8px;
            background-color: rgba(0, 0, 0, 0.05);
            margin-bottom: 4px;
            width: 100%;
            border-radius: 5px;
            border-left: 5px solid rgba(0, 0, 0, 0.06);
            color: black;
          }

          img.user-pic {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }

          .item {
            width: auto;
            display: flex;
            justify-content: flex-start;
            border-radius: 5px;
            color: #121053;
            padding: 8px;

            &::after {
              content: ' ';
              position: absolute;
              bottom: -8px;
              transform: rotate(45deg);
              border: 10px solid #e5fffa;
            }

            .message-content {
              justify-content: flex-start;
              width: auto;
              flex-flow: column;

              display: grid;

              img.attachment-pic {
                width: 150px;
                height: 150px;
                border-radius: 5px;
                margin-top: 8px;
                cursor: pointer;
              }

              a.attachment-file {
                width: 100%;
                display: flex;
                justify-content: flex-start;

                i.fa.fa-file {
                  text-align: left;
                  padding: 0;
                  margin: 0;
                  margin-top: 8px;
                }
              }
            }

            .message-footer {
              margin-top: 8px;

              i.fa-times {
                color: firebrick;
              }
            }

            .video-attachment {
              width: 300px;
              height: 300px;
              overflow: hidden;
              cursor: pointer;
              position: relative;

              img {
                position: absolute;
                width: 80px;
                height: 80px;
                left: 110px;
                top: 110px;
              }
            }
          }
        }
      }
    }
  }

  .chat-reply {
    background-color: #f8f8f8;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    z-index: 10;
    padding: 8px;
    margin-bottom: -5px;

    .item {
      width: 100%;
      border-radius: 5px;
      background-color: #e4e9f4;
      padding: 16px;
      justify-content: flex-start;
      align-content: center;
      cursor: pointer;

      span.button {
        font-size: 16pt;
        width: 35px;
        height: 35px;
        position: relative;
        top: unset;
        bottom: unset;

        i.fa {
          font-size: 16px;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .chat-shortcut {
    position: absolute;
    left: 0;
    bottom: 105px;
    background-color: whitesmoke;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    z-index: 10;

    .item {
      padding: 16px;
      justify-content: flex-start;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .modal-body {
    width: 1120px;
  }
}

@media (max-width: 1680px) {
  .conversation {
    .header {
      height: auto !important;
      grid-template-columns: 1fr 380px 363px;

      .user-attendance {
        flex-direction: column;

        >div {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 1620px) {
  .conversation {
    .conversation-content {
      .header {
        grid-template-columns: auto 1fr auto;

        .ellipse-wrapper {
          display: flex;
        }

        .header-buttons {
          display: none;
        }

        .header-buttons-wrapper {
          position: absolute;
          display: grid;
          grid-template-columns: 1fr;
          top: 70px;
          right: 0px;
          z-index: 5;
          width: 200px;
          background-color: #eee;
          padding: 10px;
          gap: 10px;
          border-radius: 10px;
        }

        .header-center {
          .tag-selector {
            width: 120px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .conversation {
    display: flex !important;
    flex-direction: column;
    height: auto !important;
  }
}

@media (max-width: 1023px) {
  .conversation {
    display: flex !important;
    flex-direction: column;

    .contact-info-right-modal {
      position: inherit;
      right: auto;
      top: auto;

      .panel-body {
        max-height: 730px;
      }
    }

    .header {
      grid-template-columns: 1fr auto !important;

      .header-container-options {
        background-color: var(--panel);
        width: 100%;
        padding: 8px;
        z-index: 5;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 5px;
      }

      .header-center {
        display: grid;
        overflow-x: auto;
        grid-template-columns: repeat(1, minmax(150px, 1fr)) 200px 60px;
        gap: 8px;
        padding: 10px !important;
        border: 0px !important;

        .tag-list {
          position: fixed;
          top: auto;
        }
      }

      .header-buttons {
        border-top: 1px solid #eee;
        overflow-x: auto;
        grid-template-columns: repeat(4, minmax(150px, 1fr)) !important;
        justify-content: stretch !important;
        padding: 8px !important;
      }
    }

    .modal {
      align-items: center;
      left: inherit;
      padding: 8px;
    }

    .conversation-sidebar {
      height: auto !important;

      .sidebar-content {
        height: auto !important;
      }
    }

    .conversation-content {
      .header {
        .header-buttons {
          display: grid !important;
          grid-template-columns: repeat(2, 1fr);
          gap: 8px;
          padding: 16px 0;

          span {
            width: 100% !important;
          }
        }
      }
    }

    .attendant-join-box {
      height: auto !important;
      width: 100%;
      left: 0;
      bottom: 0;
      color: var(--primary);

      h1 {
        font-size: 20px;
        margin: 0;
      }

      p {
        font-size: 12px;
      }

      .row {
        display: flex;
        width: 100% !important;
        flex-direction: column !important;

        .row {
          flex-direction: column;
        }
      }
    }

    .no-padding {
      width: 100% !important;

      .form-control {
        button {
          width: 100%;
        }
      }
    }

    .search-conversation {
      .panel-body {
        .filters {
          display: grid;
          grid-template-columns: repeat(4, 1fr);

          >button {
            height: 48px;
          }
        }
      }
    }

    .footer {
      padding: 8px 0px !important;

      .attachments {
        bottom: 0 !important;
      }

      textarea {
        height: 55px !important;
        padding: 20px 130px !important;
      }

      .audio-record-button,
      .send {
        top: unset !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .conversation {
    .mobile-close-button {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 22px;
      font-size: 24px;
      background-color: var(--dark-red);
      color: #ffffff;
      position: absolute;
      z-index: 10;
      top: 0;
    }

    .panel-body {
      overflow: auto;
    }
  }
}

@media (max-width: 768px) {
  .conversation {
    .conversation-content {
      height: 100%;

      .header {
        grid-template-columns: 1fr auto !important;
        z-index: 5;

        .header-container-options {
          position: absolute;
          width: 320px;
          top: 80px;
          right: 0px;

          .header-center {
            grid-template-columns: repeat(2, 1fr);
          }

          .header-buttons {
            grid-template-columns: repeat(2, 1fr) !important;
          }

          .tag-list {
            right: 0px;
            left: auto;
          }
        }
      }

      .messages {
        height: calc(100vh - 212px);
      }

      .footer {
        height: auto;
        background-color: var(--panel);

        textarea {
          height: auto;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        span,
        button {
          bottom: 10px !important;
        }
      }
    }

    .contact-info-right-modal {
      .panel-body {
        max-height: 600px;
      }
    }

    .search-conversation {
      .list {
        .list-header {
          display: none;
        }

        .list-row {
          grid-template-columns: 1fr;

          >div,
          span {
            display: grid;
            grid-template-columns: 1fr 3fr;
            height: 48px;
            text-align: left;
            align-items: center;

            &::before {
              content: attr(data-content);
              height: 100%;
              display: flex;
              align-items: center;
              padding: 8px;
              font-weight: bold;
              box-sizing: border-box;
              margin-right: 8px;
              overflow: hidden;
            }
          }
        }
      }
    }

    .contact-info-right-modal {
      .panel-body {
        overflow: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .conversation {
    .conversation-content {
      .messages {
        height: calc(100vh - 310px) !important;
      }

      input {
        font-size: 16px !important;
      }

      .modal {
        form {
          width: auto !important;
        }
      }
    }

    .search-conversation {
      .panel-body {
        .filters {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .conversation {
    .conversation-content {
      .footer {

        .audio-record-button,
        .send {
          top: 30px;
        }

        .emoji-box {
          bottom: 140px;
        }
      }
    }

    .finish-chat {
      .list {
        .list-header {
          display: none !important;
        }

        .list-row {
          grid-template-columns: 1fr !important;

          >div {
            display: grid !important;
            grid-template-columns: 1fr 2fr !important;
            text-align: left;
            align-items: center;
            width: 100% !important;
            padding: 0;

            &::before {
              content: attr(data-content);
              height: 100%;
              display: flex;
              align-items: center;
              padding: 8px;
              font-weight: bold;
              box-sizing: border-box;
              margin-right: 8px;
              overflow: hidden;
            }
          }
        }
      }
    }

    .messages {
      padding: 8px 8px 32px 8px !important;

      .message-tile {
        audio {
          width: 320px !important;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .conversation {
    .no-padding {
      .simple-table {
        .row {
          flex-direction: column;
          align-items: flex-start;

          div {
            width: 100%;
            padding: 8px 0;
          }
        }
      }
    }

    .messages {
      height: 420px !important;
    }

    .footer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 60px;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      textarea {
        padding: 16px !important;
        height: 70px !important;
      }
    }

    .conversation-content {
      .message-tile {
        audio {
          width: 250px !important;
        }
      }
    }

    .search-conversation {
      .panel-body {
        .filters {
          grid-template-columns: 1fr;

          >button {
            margin: 8px 0 0 0;
          }
        }
      }

      .list {
        .list-row {
          padding: 0;

          >div,
          span {
            grid-template-columns: 1.5fr 2fr;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .conversation {
    .conversation-content {
      .message-tile {
        audio {
          width: 220px !important;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .conversation {
    .conversation-sidebar {
      .conversation-buttons {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        .sidebar-item {
          width: 100%;
        }
      }
    }

    .conversation-content {
      .message-tile {
        audio {
          width: 160px !important;
        }
      }
    }
  }
}