@for $i from 1 through 10 {
  .padding-#{$i * 4} {
    padding: #{$i * 4}px;
  }
  .padding-left-#{$i * 4} {
    padding-left: #{$i * 4}px;
  }
  .padding-right-#{$i * 4} {
    padding-right: #{$i * 4}px;
  }
  .padding-lr-#{$i * 4} {
    padding-left: #{$i * 4}px;
    padding-right: #{$i * 4}px;
  }
  .padding-top-#{$i * 4} {
    padding-top: #{$i * 4}px;
  }
  .padding-bottom-#{$i * 4} {
    padding-bottom: #{$i * 4}px;
  }
  .padding-tb-#{$i * 4} {
    padding-top: #{$i * 4}px;
    padding-bottom: #{$i * 4}px;
  }
  .margin-#{$i * 4} {
    margin: #{$i * 4}px;
  }
  .margin-left-#{$i * 4} {
    margin-left: #{$i * 4}px;
  }
  .margin-right-#{$i * 4} {
    margin-right: #{$i * 4}px;
  }
  .margin-lr-#{$i * 4} {
    margin-left: #{$i * 4}px;
    margin-right: #{$i * 4}px;
  }
  .margin-top-#{$i * 4} {
    margin-top: #{$i * 4}px;
  }
  .margin-bottom-#{$i * 4} {
    margin-bottom: #{$i * 4}px;
  }
  .margin-tb-#{$i * 4} {
    margin-top: #{$i * 4}px;
    margin-bottom: #{$i * 4}px;
  }
}
