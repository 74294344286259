.config-container {
  .config-tab {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-weight: bold;

    .tab-option {
      padding: 16px;
      cursor: pointer;
      border-bottom: 2px solid gainsboro;

      &.active {
        border-bottom-color: var(--water-green);
      }
    }
  }
}

@media (max-width: 992px) {
  .config-container {
    display: flex;
    flex-direction: column;
    width: auto !important;

    .panel-body {
      display: grid;

      .row {
        flex-direction: column;
      }
    }

    .config-tab {
      text-align: center;
      align-items: flex-end;
    }
  }
}

@media (max-width: 414px) {
  .config-container {
    .config-tab {
      grid-template-columns: 1fr;
    }
  }
}
