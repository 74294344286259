.chatbot {
  .flow-box {
    position: relative;
    width: 100%;
    height: 650px;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-top: 16px;
    display: flex;
    gap: 16px;

  }

  .custom-drag-handle {
    gap: 8px;
    cursor: grab;
    display: flex;
  }

  .pt {
    padding-top: 16px;
  }

  .pb {
    padding-bottom: 16px;
  }

  .px {
    padding-left: 16px;
    padding-right: 16px;
  }

  .py {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .del-node-btn {
    position: absolute;
    right: 0px;
    top: 8px;
    width: 35px;
    height: 35px;
    background-color: whitesmoke;
    padding: 4px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i.fa.fa-flag,
  i.fa.fa-question-circle {
    color: var(--pink);
  }

  i.fa.fa-times {
    color: red;
  }

  i.fa.fa-arrow-right {
    color: var(--blue);
  }

  i.fa.fa-comments {
    color: var(--highlight);
  }

  i.fa.fa-bars {
    color: var(--blue);
  }

  aside {
    border: 1px solid #efefef;
    background-color: white;
    height: 100%;
    width: 310px;
    border-radius: 5px;
    padding: 16px;
    left: 16px;
    top: 16px;

    .node-selector {
      display: flex;
      padding: 12px 16px;
      border: gainsboro;
      border-radius: 4px;
      margin: 8px 0;
      background-color: #efefef;
      cursor: grab;
    }

    .node-label {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      margin: 12px 0;

      .name {
        padding: 0 8px;
        background-color: white;
        z-index: 2;
      }

      .line {
        height: 1px;
        width: 100%;
        z-index: 1;
        background-color: gainsboro;
        position: absolute;
      }
    }
  }

  .node {
    display: flex;
    min-width: 100px;
    flex-direction: column;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    overflow: hidden;
    margin: 8px 0;
    background-color: white;

    i.fa {
      font-size: 22px;
    }

    .divisor {
      margin-top: 16px;
      padding: 16px 0;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
    }

    .node-button {
      padding: 16px;
      border-radius: 10px;
      text-align: center;
      border: 2px dotted var(--blue);
      color: var(--blue);
      width: 100%;
      margin: 16px 0;
      background-color: white;
    }

    .options {
      width: 100%;

      .option-item {
        gap: 8px;
        margin: 4px 0;
        display: grid;
        width: 100%;
        position: relative;
        grid-template-columns: 50px 1fr;

        .option-key {
          position: relative;

          input {
            text-align: center;
            z-index: 0;
          }

          .remove-option-item {
            display: none;
            cursor: pointer;
          }

          &:hover .remove-option-item {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-items: center;
            justify-content: center;
            align-items: center;
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.6)
          }
        }
      }
    }
  }
}