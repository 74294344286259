.tag-container {
  .gradient {
    width: 120px;
  }

  .modal-body {
    width: 500px;
  }
}

.tag-selector {
  width: 100%;
  position: relative;
  border: 1px solid gainsboro;
  border-radius: 5px;

  .tag-selected {
    width: 100% !important;
    display: grid;
    padding: 8px 12px;
    grid-template-columns: 20px 1fr 20px;
    border-left: 5px solid gray;
    align-content: center;

    p {
      margin: 0;
      font-weight: bold;
      font-size: 12px;
    }

    i {
      margin: 0;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }

  .tag-list {
    display: flex;
    position: absolute;
    top: 50px;
    left: 0;
    width: 180px;
    z-index: 5;
    border-radius: 5px;
    overflow: hidden;
    padding-top: 8px;

    color: #1f1f1f;

    .items {
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 4px;
      margin-right: 4px;
      width: 100%;

      .item {
        height: 48px;
        display: flex;
        cursor: pointer;
        padding: 12px;
        align-content: center;
        justify-content: flex-start;
        border-left: 5px solid gray;
        background-color: var(--light);
        color: var(--panel-text);

        font-size: 14px;
      }
    }

    &::before {
      content: ' ';
      width: 10px;
      height: 10px;
      background-color: white;
      transform: rotate(45deg);
      position: absolute;
      top: 4px;
      right: 30px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .tag-container {
    .list {
      .list-header {
        grid-template-columns: 50px 1fr 130px 100px 160px;
      }

      .list-row {
        grid-template-columns: 50px 1fr 130px 100px 165px;
      }
    }
  }
}
