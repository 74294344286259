.survey-report-container {
  gap: 8px;

  .gap-8 {
    gap: 8px;
  }
}

@media screen and (min-width: 1280px) {
  .survey-report-container {
    .list {
      .list-header {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .list-row {
        grid-template-columns: 1fr 1fr 1fr;

        .row {
          gap: 4px;
        }
      }
    }
  }
}