.layout-panel {
  .navbar {
    height: 100%;
    background-color: var(--panel);
    align-content: center;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 150px 70px;
    color: var(--panel-text);
    justify-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #ffffff1a;

    img.user-pic {
      border-radius: 50%;
    }

    .info-box {
      position: absolute;
      width: 200px;
      right: 16px;
      top: 55px;
      padding: 16px;
      font-size: 10pt;
    }

    .channels {
      width: 100%;
      justify-content: flex-start;
      padding: 0 16px;
      overflow-x: auto;
      flex-wrap: nowrap;
      justify-items: flex-start;

      .channel-box {
        width: auto;
        display: flex;
        justify-content: flex-start;
        margin-right: 8px;

        span {
          padding: 0 0 0 6px;
        }

        .button.btn-channel {
          border-radius: 25px;
          height: 40px;
          margin-right: 8px;
          color: white;

          &.disconnected {
            background-color: var(--red);
          }

          &.connected {
            background-color: var(--green);
          }
        }
      }
    }

    .channel-config-container {
      padding: 0;
      max-width: 768px;
      width: 100%;
      display: grid;
      color: var(--dark-gray);

      .channel-config-content {
        .bg-status-channel {
          background: var(--light-blue);
          border-radius: 10px;
        }
      }

      .channel-form-config {
        padding: 24px;
        background-color: var(--white-gainsboro);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    .nav-info-icon {
      width: 150px;
      justify-self: flex-end;
      justify-content: flex-end;

      .icon-rounded {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--light-purple);
        margin: 0 6px;
        border-radius: 50px;
        height: 35px;
        width: 35px;
      }

      .icon-quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 9pt;
        padding: 4px;
        min-width: 24px;
        min-height: 24px;
        height: auto;
        width: auto;
        position: absolute;
        top: -8px;
        right: -8px;
        background-color: var(--pink);
        color: white;
        border-radius: 12px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .layout-panel {
    .navbar {
      grid-template-columns: 35px 0 1fr 61px;
      width: 100vw;
      padding: 0 10px;

      span {
        margin: 0 8px;

        img {
          height: 25px;
        }
      }

      .switch {
        width: 65px;
      }
    }
  }
}
