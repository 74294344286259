.input-number {
  padding: 0;

  &.disabled {
    pointer-events: none;
    
    .controls {
      i {
        color: #D9D9D9;
      }
    }
  }

  .editable {
    flex: 1;
    padding: 8px 16px;
  }
  .controls {
    display: flex;
    height: 48px;
    padding: 8px 10px;
    flex-direction: column;
    border-left: 1px solid var(--input-border);

    i {
      margin: 0;
    }
  }
}