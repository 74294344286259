.form-config-container {
  padding: 0;
  color: var(--dark-gray);

  .form-config {
    padding: 24px;
    background-color: var(--white-gainsboro);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .button-times {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 22px;
      font-size: 24px;
      background-color: var(--dark-red);
      color: #ffffff;
    }
  }

  .data-option {
    flex: 1;
    text-align: center;
    cursor: pointer;
    margin-bottom: 16px;
    padding: 16px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.5s;

    &:hover {
      border-bottom: 1px solid var(--water-green);
    }
  }
}