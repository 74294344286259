.audio-record-button {
  .record-signal {
    background-color: red;
    border-radius: 50%;
    height: 12px;
    width: 12px;
  }

  i.fa.fa-times-circle {
    color: red;
    font-size: 18pt;
    margin: 0;
    margin-right: 16px;
  }
  i.fa.fa-check-circle {
    color: var(--primary);
    font-size: 18pt;
    margin: 0;
    margin-left: 16px;
  }
}