.message-base-container {
  .modal-body {
    .panel {
      .buttons {
        gap: 8px;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .message-base-container {
    .list {
      .list-header {
        grid-template-columns: 50px 1fr 1fr 150px 150px 160px;
      }

      .list-row {
        grid-template-columns: 50px 1fr 1fr 150px 150px 160px;

        .row {
          gap: 4px;
        }
      }
    }
  }
}