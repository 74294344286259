#root {
  --dark-blue: #121053;
  --light-blue: #e4e9f4;
  --light-purple: #f4eeff;
  --white-alpha: #f5f8ff;
  --white: #ffffff;
  --white-gainsboro: #f5f5f5;
  --blue: #0369d1;
  --water-green: #0e8e7f;
  --green: #1daf1e;
  --green-alpha: #0fb76b1f;
  --red: #ec0000;
  --dark-red: #ef3737;
  --red-alpha: #ec00001f;
  --carbon: #404965;
  --light-gray: #fdfdfd;
  --light-gray-secondary: #ebebeb;
  --gray: #dddede;
  --border-gray: #dee2e6;
  --dark-gray: #76838f;
  --text-black: #212529;
  --pink: #ff4081;
  --light: #f4f6fc;
}

[data-theme='dark'] {
  --background: #292929;
  --panel: #1e1e1e;
  --light-purple: #292929;
  --white-alpha: #292929;
  --carbon: #ffffff;
  --white: #1e1e1e;
  --panel-text: #dcdcdc;
  --primary: #1f1f1f;
  --dark-blue: #1f1f1f;
  --input-background: #1f1f1f;
  --input-text: #ffffff;
  --input-border: #ffffff1a;
  --light: #1f1f1f;
  --border-gray: #1f1f1f;
  --light-blue: #1f1f1f;
  --carbon: #f7f7f7;
  --white-gainsboro: #1f1f1f;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.grid-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

div[type='donut'] {
  width: 100%;
}

textarea {
  font-family: inherit;
  font-size: 16px;
}

html,
body {
  font-size: 14px;
}

input:disabled,
textarea:disabled {
  opacity: 0.4;
}

hr {
  opacity: 0.2;
}

button {
  height: 40px;
}

.panel-body-button {
  gap: 8px;

  button,
  span {
    min-width: 200px;
  }
}

.button,
button {
  > i.fa-edit,
  i.fa-download {
    color: var(--green);
  }

  > i.fa-cog {
    color: var(--blue);
  }

  > i.fa-trash,
  i.fa-trash-alt {
    color: var(--red);
  }
}

.simple-table {
  border: 1px solid gainsboro;
  width: 100%;
  border-radius: 10px;

  .row {
    padding: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid gainsboro;
    }
  }
}

.quantity {
  position: absolute;
  border-radius: 13px;
  min-height: 27px;
  min-width: 27px;
  width: auto;
  height: auto;
  padding: 4px;
  right: -4px;
  top: -4px;
  background-color: var(--pink);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    height: 16px;
    width: 16px;
    font-size: 9pt;
  }
}

.menu .quantity,
.chat-attendants .quantity {
  right: 20px;
}

.content-width {
  max-width: 1280px;
}

.rgba {
  background-color: rgba(0, 0, 0, 0.15);
}

.form-buttons {
  justify-content: flex-end;

  .button,
  button {
    width: 200px;
  }
}

.height-100 {
  height: 100%;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-box {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:disabled + .slider {
    opacity: 0.4;
  }

  input:checked + .slider {
    background-color: var(--highlight);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--highlight);
  }

  input {
    + .slider {
      background-color: #dddede;

      &:before {
        transform: translateX(0px);
      }
    }
  }

  input:checked {
    + .slider {
      &:before {
        transform: translateX(20px);
      }
    }
  }

  &.mark {
    input {
      + .slider {
        background-color: red;

        &:before {
          transform: translateX(20px);
        }

        &::after {
          content: '\00D7';
          font-size: 20px;
          position: absolute;
          left: 9px;
          top: 2px;
          color: #fff;
        }
      }
    }

    input:checked {
      + .slider {
        background-color: var(--highlight);

        &:before {
          transform: translateX(0px);
        }

        &::after {
          content: '\2714';
          left: unset;
          font-size: unset;
          position: absolute;
          right: 11px;
          top: 3px;
          color: #fff;
        }
      }
    }
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 7.5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--highlight);
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--highlight);
  cursor: pointer;
}

.global-style-container {
  border-radius: 10px;

  .form-config-container {
    padding: 0;
    display: grid;
    color: var(--carbon);

    .form-config {
      padding: 16px 24px;
      background-color: var(--white-gainsboro);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .button-times {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ebebeb;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 22px;
        font-size: 24px;
        background-color: var(--dark-red);
        color: #ffffff;
      }
    }

    .data-option {
      flex: 1;
      text-align: center;
      cursor: pointer;
      margin-bottom: 16px;
      padding: 16px;
      border-bottom: 1px solid transparent;
      transition: border-bottom 0.5s;

      &:hover {
        border-bottom: 1px solid var(--water-green);
      }
    }

    .form-buttons {
      margin-top: 32px;

      button {
        background: transparent
          linear-gradient(90deg, var(--blue) 0%, var(--water-green) 100%) 0% 0%
          no-repeat padding-box;
        color: var(--white);
        height: 50px;
        transition: background 1s;
        border-radius: 5px;

        &:hover {
          background: var(--blue);
        }
      }

      span {
        border-radius: 5px;
        height: 50px;
      }
    }
  }

  .button-register {
    background: transparent
      linear-gradient(90deg, var(--blue) 0%, var(--water-green) 100%) 0% 0%
      no-repeat padding-box;
    color: var(--white);
    border-radius: 25px;
    width: 100%;
    height: 36px;
    transition: background 1s;

    &:hover {
      background: var(--blue);
    }
  }
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.list {
  .list-header {
    color: var(--carbon);
  }

  .empty {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.gap-10 {
  gap: 10px
}

@media (max-width: 992px) {
  .global-style-container {
    .form-config-container {
      width: 100%;
    }
  }

  form {
    .form-buttons {
      button,
      span {
        flex: 1;
      }
    }
  }
}

@media (max-width: 576px) {
  .global-style-container {
    .form-buttons {
      span,
      button {
        flex: 1;
      }
    }
  }
}
