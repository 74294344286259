.person-container {
  .modal-body {
    .form-buttons {
      gap: 8px;
    }

    .hero {
      background-color: var(--background);
    }
  }

  .upload,
  .upload .placeholder,
  .upload .placeholder-content {
    border-radius: 50%;
    overflow: hidden;
    border: none;
  }

  .upload-button {
    padding: 8px;
    background-color: #e4e9f4;
    position: absolute;
    color: #76838f;
    z-index: 10;
    border-radius: 50%;
    font-size: 10pt;
    right: 0px;
    bottom: 0px;
  }

  .upload {
    border: 1px solid whitesmoke;
  }

  .contact-tab {
    width: 100%;
    display: grid;
    font-weight: bold;
    grid-template-columns: repeat(4, 1fr);

    .tab-option {
      padding: 16px;
      cursor: pointer;
      border-bottom: 2px solid gainsboro;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.active {
        border-bottom-color: var(--water-green);
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .person-container {
    .list {
      .list-header {
        grid-template-columns: 60px 1fr 1fr 130px 160px;
      }

      .list-row {
        grid-template-columns: 60px 1fr 1fr 130px 165px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .person-container {
    .modal-body {
      .contact-tab {
        grid-template-columns: 1fr;
      }
    }
  }
}
