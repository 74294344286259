.groups-container {
  .modal-body {
    .buttons {
      gap: 8px;
      padding: 20px 0px;
    }
  }

  .custom-group-wrapper {
    .list {
      .list-header {
        grid-template-columns: 50px 1fr 150px;
      }

      .list-row {
        grid-template-columns: 50px 1fr 150px;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .groups-container {
    .list {
      .list-header {
        grid-template-columns: 50px 1fr 1fr 130px 130px 160px;
      }

      .list-row {
        grid-template-columns: 50px 1fr 1fr 130px 130px 160px;
      }
    }
  }
}

@media (max-width: 1279px) {
  .groups-container {
    .custom-group-wrapper {
      .list {
        .list-header {
          display: none;
        }
    
        .list-row {
          grid-template-columns: 1fr;
    
          >div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            text-align: left;
            align-items: center;
    
            &::before {
              content: attr(data-content);
              align-items: center;
            }
          }
        }
      }
    }
  }
}