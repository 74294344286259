.schedule-container {
  .execution {
    .hero {
      display: grid;
      overflow-x: auto;
      grid-template-columns: repeat(4, 1fr);
      background-color: var(--background);
    }
  }
}

@media screen and (min-width: 1280px) {
  .schedule-container {
    .list {
      .list-header {
        grid-template-columns: 50px 1fr 1fr 120px 130px 100px 260px;
      }

      .list-row {
        grid-template-columns: 50px 1fr 1fr 120px 130px 100px 260px;

        .buttons-list-row {
          .row {
            gap: 4px;
          }
        }
      }
    }

    .execution {
      .list {

        .list-header,
        .list-row {
          grid-template-columns: 50px 1fr 130px 130px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .schedule-container {
    .modal-box {
      .modal-body {
        .buttons {

          span,
          button {
            flex: 1;
          }
        }
      }
    }

    .execution {
      .hero {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }
  }
}