.panel {
  @extend .border-radius;
  padding: 24px;
  background-color: var(--panel);
  color: var(--panel-text);

  &.bordered {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
  }

  &.no-padding {
    padding: 0;
  }

  .panel-header {
    width: 100%;
    background-color: var(--background);
    padding: 16px 24px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--panel-text);

    i {
      margin: 0;
    }

    .grow {
      p {
        margin-top: 0;
        font-size: 21px !important;
        text-align: left;
      }
    }

    .button-times {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 22px;
      font-size: 24px;
      background-color: var(--dark-red);
      color: #FFFFFF;
    }
  }

  .panel-body {
    width: 100%;
    padding: 24px;
    background-color: var(--white);
    color: var(--panel-text);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .buttons {
      justify-content: flex-end;

      .button,
      button {
        width: 120px;
        font-weight: normal
      }
    }
  }
}

.hero {
  @extend .border-radius;
  display: flex;
  padding: 16px;
  width: 100%;
  min-height: 48px;
  background-color: #e4e9f4;
}

.square {
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .content {
    overflow: inherit !important;
    padding: 0 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.delete {
  border: 1px solid rgb(133, 28, 28) !important;
  color: rgb(133, 28, 28);
  background-color: transparent;

  .fa {
    margin: 0;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center;
}

.border-radius {
  border-radius: 5px;
}

.bold {
  font-weight: bolder;
}

.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.alpha {
  opacity: 0.5;
}

.flex {
  flex: 1;
}

.grow {
  flex: 1;
  flex-grow: 1;
  width: auto;
  flex-flow: column;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}