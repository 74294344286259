@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "./Column.scss";
@import "./Button.scss";
@import "./Form.scss";
@import "./Colors.scss";
@import "./Spaces.scss";
@import "./Align.scss";
@import "./Texts.scss";
@import "./Helpers.scss";
@import "./Badge.scss";
@import "./AlertBox.scss";

@import "./components/List.scss";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
#root {
  display: flex;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  outline: 0;
  justify-content: stretch;
  align-self: stretch;
  font-family: "Roboto", sans-serif;
}

body {
  overflow: hidden !important;
  overflow-y: auto !important;
  background-color: var(--background);
  /*  color: var(--background-text); */
  color: #404965;
  word-break: break-word;
}

a {
  text-decoration: none;
  color: var(--panel-text);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  width: 100%;
}

i.fa,
i.fab,
i.fas,
i.far {
  margin: 0 8px;
}

.shadow {
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
}

.empty {
  background-color: var(--panel-1);
  padding: 16px;
  border-radius: 5px;
  color: gray;
  color: var(--panel-1-text);
}

.panel-border {
  padding: 16px;
  border-radius: 5px;
  border: 1px solid gainsboro;
}

.tile {
  background-color: white;
  margin: 8px 0;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #121053;
}

.hidden-desk {
  display: none !important;
}

.img-circle {
  border-radius: 100%;
}

@media screen and (min-width: 175px) and (max-width: 1023px) {
  .hidden-desk {
    display: inherit !important;
  }

  .hidden-mobile {
    display: none !important;
  }
}