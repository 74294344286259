.row {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  position: relative;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    width: percentage(calc($i / 12));
  }
}

@media screen and (min-width: 175px) and (max-width: 1023px) {
  @for $i from 1 through 12 {
    .col-#{$i} {
      width: 100%;
    }
    .mob-#{$i} {
      width: percentage(calc($i / 12)) !important;
    }
    .mob-h-#{$i} {
      height: percentage(calc($i / 12)) !important;
    }
  }
}