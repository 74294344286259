.select {
  width: 100%;
  position: relative;
  
  textarea:focus, input:focus{
    outline: none;
  }

  .search-box {
    width: 100%;
    min-height: 48px;
    max-height: 250px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    background-color: white;
    border-radius: 0 0 5px 5px;
    border: 1px solid #efefef;
    position: absolute;
    top: 46px;
    z-index: 5;
    overflow: auto;

    .search-item {
      color: #1f1f1f;
      cursor: pointer;
      padding: 16px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover, &.active {
        background-color: #efefef;
      }
    }
  }
}