.alert-box {
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  margin: 8px 0;
  font-size: 16px;
  
  > .button {
    height: 40px;
    margin-left: 16px;
  }

  &.success, &[data-status='CONFIRMED'], &[data-status='ACTIVE'] {
    color: var(--green);
    background-color: var(--green-alpha);
    
    .button {
      color: var(--green-alpha);
      background-color: var(--green);
    }
  }

  &.danger, &[data-status='REJECTED'] {
    color: #f80031;
    background-color: #fdd1da;

    .button {
      color: #fdd1da;
      background-color: #f80031;
    }
  }

  &.warning, &[data-status='REFUNDED'], &[data-status='EXPIRED'] {
    color: brown;
    background-color: #ffebc7;
    
    .button {
      color: #ffebc7;
      background-color: brown;
    }
  }

  &.secondary, &[data-status='PENDING'], &[data-status='INACTIVE'] {
    color: gray;
    background-color: whitesmoke;
    
    .button {
      color: whitesmoke;
      background-color: gray;
    }
  }
  
  &.default, &[data-status='PROCESSING'] {
    color: #fff;
    background-color: #4172c6;
    
    .button {
      color: #4172c6;
      background-color: #fff;
    }
  }

  &.info {
    color: #0091FF;
    background-color: #A6D9FF;
    
    .button {
      color: #A6D9FF;
      background-color: #0091FF;
    }
  }
}