.client-manage {
  .custom-header-client {
    display: grid;
    grid-template-columns: 1fr 400px;
    align-content: end;
  }

  .filter {
    display: grid;
    grid-template-columns: 2fr 1fr 150px 150px 120px;
    border-radius: 0px;
    border: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }


  .list {
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .list-header,
    .list-row {
      grid-template-columns: 50px 70px 1fr 100px 100px 130px 130px 200px;
      gap: 8px;
      border-radius: 0px;

      .buttons-list-row {
        .row {
          gap: 4px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .client-manage {
    .filter {
      grid-template-columns: 1fr 1fr repeat(2, 150px) 120px;
    }
  }
}

@media (max-width: 1280px) {
  .client-manage {
    .filter {
      grid-template-columns: repeat(2, 1fr);

      .button {
        grid-column: 1 / 3;
        margin-top: 10px;
      }
    }

    .list {
      .list-header {
        display: none;
      }

      .list-row {
        grid-template-columns: 1fr;
        gap: 10px;

        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          text-align: left;
          align-items: center;

          &::before {
            content: attr(data-content);
            align-items: center;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .client-manage {
    .custom-header-client {
      grid-template-columns: 1fr;

      &:nth-child(1) {
        div {
          justify-content: space-between;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .client-manage {
    .form-config {
      .row {
        flex-direction: row !important;
      }
    }

    .form-config-container {
      div {
        .row {
          flex-direction: column;
          padding-left: 0;

          .form-control {
            width: 100%;
          }
        }
      }

      .form-buttons {
        flex-direction: column;
        margin-top: 0;

        span,
        button {
          width: 100%;
          margin: 4px 0;
        }
      }
    }
  }
}