@import "./Column.scss";

form {
  width: 100%;
}

input::-webkit-color-swatch {
  border: none;
}

.form-control {
  @extend .row;
  margin-top: 8px;
  padding: 0 4px;

  span.validation {
    display: none;
  }
  &.invalid {
    span.validation {
      color: red;
      display: inherit;
      position: absolute;
      right: 0;
      top: 8px;
      font-size: 10pt;
    }
    label {
      color: red;
    }
    input,
    select {
      border-color: red;
    }
  }
}

label {
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

input[type="date"],
input[type="time"],
input[type="color"] {
  -webkit-appearance: none;
}

input[type="color"] {
  padding: 0px;
  border: none;
}

input:disabled {
  opacity: 0.8;
}

input,
textarea,
select,
.input {
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  height: 48px;
  font-size: 16px;
  background-color: var(--input-background);
  color: var(--input-text);
  display: flex;
  align-items: center;

  &.transparent {
    background-color: transparent;
    color: white;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--panel) inset;
  -webkit-text-fill-color: var(--input-text);
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

textarea {
  height: auto;
  resize: vertical;
  min-height: 48px;
}

input[required],
textarea[required],
select[required],
.input.required {
  border-left: 2px solid var(--dark-gray);
}

input[type="checkbox"],
input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 4px;
}

label.checkbox {
  width: auto;
  display: flex;
  position: relative;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;

  // Hide Input
  input[type="checkbox"] {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    width: 22px!important;
    height: 22px!important;
    display: flex;
    border-radius: 50%;
    margin-right: 4px;
    background-color: white;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 3px solid #dddede;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: rgba(0, 0, 0, 0.1);
  }

  /* When the checkbox is checked, add a background */
  input:checked ~ .checkmark {
    background-color: white;
    border-color: var(--water-green);

    &::before {
      content: " ";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--water-green);
    }
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
}
