.role {
  background-color: whitesmoke;
  background-color: var(--panel-1);
  margin-top: 16px;
  padding: 16px;
  border-radius: 5px;
  justify-content: flex-start;
  transition: border-color 0.2s ease-in-out;
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    border-color: rgba(0, 0, 0, 0.23);

    span.role-name {
      background-color: rgba(0, 0, 0, 0.23);
    }

    .role-select {
      border: 2px solid rgba(0, 0, 0, 0.5);

      &::before {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.active {
    border-color: green;

    span.role-name {
      background-color: green;
    }

    .role-select {
      border: 2px solid green;

      &::before {
        background-color: green;
      }
    }
  }

  h3 {
    text-align: left;
  }

  p,
  h3 {
    flex: 1;
    padding-left: 8px;
  }

  .role-name {
    min-width: 180px;
    text-align: center;
    padding: 8px;
    font-size: 10pt;
    font-weight: normal;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--primary-text);
    transition: background-color 0.2s ease-in-out;
  }

  .role-permission {
    width: auto;
    margin: 8px;
    padding: 16px;
    display: flex;
    flex-flow: column wrap;
    white-space: nowrap;
    border: 1px dashed gainsboro;
    border-radius: 5px;
    height: 105px;

    &.inherit {
      background-color: rgba(0, 0, 0, 0.2);
      border-right: 5px solid rgba(0, 0, 0, 0.2);
    }

    p {
      text-align: center;
      margin-bottom: 8px;
    }

    .remove-bg {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      transition: opacity 0.2s ease-in-out;
      display: flex;
      opacity: 0;
      color: white;
      justify-content: center;
      align-items: center;
      font-size: 20pt;
      cursor: pointer;
    }

    &:hover .remove-bg {
      opacity: 1;
    }
  }

  .inherit-box {
    margin-top: 5px;
    display: flex;
    width: 100%;
    height: 115px;
    overflow-x: scroll;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.1) !important;
    }

    .role-permission {
      margin: 0;
      margin-right: 8px;
    }
  }

  .role-open-btn {
    position: absolute;
    right: 8px;
    top: 12px;
    background-color: rgba(0, 0, 0, 0.05);
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 1024px) {
  .user-manager_ {
    .role-extra-permissions {
      .role-mobile {
        justify-content: flex-end;
        padding: 16px 0;

        .role-inputs {
          width: 100% !important;
        }

        span {
          margin-top: 8px;
        }
      }
    }
  }
}