.seller-manage {
  .seller-head {
    padding: 12px;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 0px;
    overflow: auto;

    .filter {
      display: grid;
      grid-template-columns: repeat(5, 1fr) 100px;
    }
  }

  .list {
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .list-header,
    .list-row {
      grid-template-columns: 50px 80px repeat(1, minmax(250px, 1fr)) repeat(3, 100px) repeat(2, 200px) 105px;
      gap: 8px;
      border-radius: 0px;

      .buttons-list-row {
        .row {
          gap: 4px
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .seller-manage {
    .seller-head {
      .filter {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media (max-width: 480px) {
  .seller-manage {
    .form-config {
      .row {
        flex-direction: row !important;
      }
    }

    .form-config-container {
      div {
        .row {
          flex-direction: column;
          padding-left: 0;

          .grow {
            width: 100%;
          }
        }
      }

      .form-buttons {
        flex-direction: column;
        margin-top: 0;

        span,
        button {
          width: 100%;
          margin: 4px 0;
        }
      }
    }
  }
}