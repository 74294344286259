.gradient {
  color: white;
  background: transparent linear-gradient(90deg, rgba(3, 105, 209, 1) 0%, rgba(0, 236, 188, 1) 100%) 0% 0% no-repeat padding-box;
}

.white {
  color: var(--panel-text);
  background-color: var(--white);
}

.primary {
  background-color: var(--primary);
  color: var(--primary-text) !important;

  &.bordered {
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary) !important;
  }
}

.secondary {
  background-color: var(--secondary);
  color: var(--secondary-text);
}

.primary-text {
  color: var(--primary-textz);
}

.text-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.highlight {
  background-color: var(--highlight);
  color: var(--highlight-text);
}

.highlight-text {
  color: var(--highlight);
}