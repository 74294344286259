.pagination {
  margin-top: 16px;

  .button {
    background-color: var(--panel);
    color: var(--panel-text);
    position: relative;
    overflow: hidden;
    border: 1px solid var(--input-border);
    height: 33px;
    width: auto;
    border-radius: 0px !important;
    margin-left: -2px;
    padding: 16px 12px;

    &:first-child {
      border-top-left-radius: 5px!important;
      border-bottom-left-radius: 5px!important;
    }

    &:last-child {
      border-top-right-radius: 5px!important;
      border-bottom-right-radius: 5px!important;
    }

    i.fa {
      margin-bottom: -4px;
    }

    &:hover {
      &::before {
        content: " ";
        background-color: rgba(0, 0, 0, 0.2);
        mix-blend-mode: overlay;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    &.active {
      background-color: #0369D1;
      color: white;
    }
  }
}

@media (max-width: 414px) {
  .pagination {
    align-content: center;
    flex-direction: column;
    gap: 10px;
  }
}
