button,
.button {
  padding: 16px;
  border: 0;
  border-radius: 5px;
  height: 48px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--button-text);
  position: relative;
  cursor: pointer;

  &.small {
    height: 32px;
  }

  &.radius {
    border-radius: 25px;
  }

  &.error {
    color: firebrick;
    border: 1px solid firebrick;

    i {
      color: firebrick !important;
    }
  }

  &:disabled {
    opacity: 0.8;
  }

  &.remove {
    border: 1px solid gray;
    color: gray;

    &:hover {
      border: 1px solid firebrick;
      color: firebrick;
    }
  }

  &.rounded {
    border-radius: 50%;
  }

  &.right {
    position: absolute;
    right: -16px;
  }
}
