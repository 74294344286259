@for $i from 1 through 5 {
  .font-#{$i * 8} {
    font-size: #{$i * 8}pt;
  }
}

h1, h2, h3, h4, h5, h6, h7 {
  margin: 0;
}

p {
  margin: 0;
  margin-top: 4px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}