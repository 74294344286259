.layout-panel {
  width: 100%;
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 70px 1fr;
  align-self: stretch;
  align-items: flex-start;
  background-color: var(--background);

  .navbar {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .sidebar {

    .fa-angle-right,
    .img-logo-icon {
      display: none;
    }

    .fa-angle-left,
    .img-logo {
      display: flex;
    }
  }

  .content {
    overflow: hidden;
    overflow-y: auto;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 100%;
    height: calc(100vh - 70px);
    scroll-behavior: smooth;
    padding: 32px;
    position: relative;

    .disconnected-channels {
      width: 100%;
      padding: 8px;
      padding-top: 18px;
      color: white;
      border-radius: 5px;
      background-color: red;
      justify-content: flex-start;
      margin-bottom: 16px;

      .top {
        position: absolute;
        background-color: firebrick;
        padding: 6px 12px;
        border-radius: 20px;
        z-index: 2;
        top: -12px;
      }

      .item {
        width: auto;
        padding: 12px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);

        .margin-lr-8 {
          margin-left: 0;
        }
      }
    }
  }

  &.collapsed {
    grid-template-columns: 90px 1fr;

    .sidebar {
      grid-template-rows: 80px 1fr 132px;

      .fa-angle-left,
      .img-logo {
        display: none;
      }

      .fa-angle-right,
      .img-logo-icon {
        display: flex;
      }

      .menu {
        margin: 0;

        .label,
        .menu-label {
          display: none;
        }

        .quantity {
          right: 0;
        }

        li a {
          width: 56px;
          height: 56px;

          svg {
            width: 30px;
            height: 30px;
            margin-right: 0;
          }
        }
      }

      .profile-config {
        display: flex;
      }

      .sidebar-footer {
        display: none;
      }
    }
  }

  &.conversation-page {
    .angle-btn {
      display: none !important;
    }

    .content {
      grid-row: 2 / 3;
      padding: 0;
      padding-left: 24px;
    }
  }

  .footer {
    justify-content: center;
    width: 100%;
    height: 65px;
  }
}

.list-row {
  &.empty {
    grid-template-columns: 1fr !important;
    text-align: center;
  }
}

@media screen and (max-width: 1023px) {
  .layout-panel {
    grid-template-columns: 1fr;

    .navbar {
      grid-row: 1 / 1;
      grid-column: 1 / 2;
    }

    .content {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      padding: 16px;
      height: auto !important;

      .angle-btn {
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: var(--primary);
        left: -8px;
        top: 10px;
        height: 20px;
        padding: 4px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        position: fixed;
        transform: rotate(90deg);

        .fa-angle-left {
          display: none;
        }

        .fa-angle-right {
          transform: rotate(-90deg);
          font-size: 20px;
          color: var(--water-green);
        }
      }
    }

    &.collapsed {
      grid-template-columns: 1fr;

      .sidebar {
        grid-template-rows: 70px 1fr 70px;

        .img-logo {
          display: flex;
        }

        .img-logo-icon {
          display: none;
        }

        .menu {
          margin: 0;

          a {
            width: 100% !important;

            .label {
              display: flex;
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
}

.new-badge {
  position: absolute;
  top: -8px;
  right: 8px;
  border-radius: 8px;
  background-color: red;
  color: white;
  padding: 4px 8px;
  font-size: 8pt;
}