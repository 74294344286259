.user-manager_ {
  .role-userlist {
    .role-inputs {
      .form-control {
        flex: 1;
      }
    }
  }
}

@media (max-width: 992px) {
  .mobile-responsive {
    flex-direction: column;

    h2 {
      text-align: center;
    }

    .profile-form-wrapper {
      padding-left: 0;

      .wrapper-form {
        margin-top: 32px;
      }
    }
  }
  
  .user-manager_ {
    

    .role-name {
      flex: 1;
    }

    .role-extra-permissions {
      .role-mobile {
        .role-header {
          display: flex;
          align-self: flex-start;
          padding: 16px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .role-extra-permissions {
    .role-mobile {
      flex-direction: column;

      .role-inputs {
        width: 100% !important;
      }

      span {
        margin-top: 8px;
        margin-right: 4px;
      }
    }
  }
  .role-userlist {
    flex-direction: column;
    .grow {
      width: 100%;

      .role-inputs {
        flex-direction: column;

        .form-control {
          margin-top: 8px !important;
        }
      }
    }

    button {
      align-self: flex-end;
    }
  }
}

@media (max-width: 576px) {
  .user-manager_ {
    .role {
      flex-direction: column;
      align-items: flex-start;
    }

    .responsive-btn {
      button,
      span {
        flex: 1;
      }
    }

    .list {
      .list-header {
        display: grid;
        grid-template-columns: 1fr !important;

        div {
          margin: 4px 0;
        }
      }

      .list-row {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        div {
          margin: 4px 0;
        }

        a {
          width: 90px;
        }
      }
    }

    .role-permission {
      p {
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 480px) {
  .mobile-responsive {
    .responsive-btn {
      flex-direction: column;
      button,
      span {
        margin: 8px 0 0 0;
        width: 100%;
      }
    }
  }
}
