.layout-panel {
  .sidebar {
    background: var(--primary);
    transition: all 0.3s ease-in-out;
    z-index: 5;
    display: grid;
    grid-template-rows: 70px 1fr 50px;
    transition: all 1s ease;
    position: relative;
    height: 100vh;
    border-right: 1px solid #ffffff1a;

    .profile-config {
      display: none;
      grid-row: 3 / 4;
      padding-bottom: 16px;
      position: relative;

      .config-btn {
        padding: 0;
        font-size: 30pt !important;
        width: 56px;
        height: 56px;

        i {
          color: #6764b4;
          font-size: 30px;
        }

        &:hover {
          background: #ffffff1a;
          border-radius: 5px;

          i {
            color: var(--highlight);
          }
        }
      }

      .info-box {
        display: grid;
        width: 200px;
        position: absolute;
        left: 24px;
        bottom: 58px;
        padding: 8px 16px;
      }

      img {
        border-radius: 50%;
      }
    }

    .logo {
      img {
        width: 100%;
        padding: 24px;
      }
    }

    .menu-content {
      grid-row: 2/3;
      overflow: auto;
      height: 100%;
      padding: 0px 16px;
    }

    .sidebar-footer {
      color: var(--highlight);
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      min-height: 48px;
      align-items: stretch;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    .config-button {
      background: var(--dark-blue);
      transition: background 1s;

      &:hover {
        background: transparent linear-gradient(90deg, var(--blue) 0%, var(--water-green) 100%) 0% 0% no-repeat padding-box;
        color: var(--white);
      }
    }

    .angle-btn {
      display: none;
      background: var(--dark-blue);
      font-size: 24px;
      position: absolute;
      right: -20px;
      top: 50px;
      color: var(--highlight);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 8px;
      cursor: pointer;

      i {
        margin-right: 3px;
      }
    }

    &:hover {
      .angle-btn {
        display: flex;
      }
    }
  }

  .sidebar-bg {
    display: none;
  }
}

@media screen and (min-width: 175px) and (max-width: 1023px) {
  .layout-panel {
    .sidebar {
      width: 100%;
      height: 100%;
      left: -100%;
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      transition: all 0.3s ease-in-out;
      z-index: 7;
      position: fixed;

      &.open {
        width: 250px;
        left: 0px;
      }

      .profile-config {
        div {
          flex: 1;
          height: auto;
        }
      }
    }

    .sidebar-bg {
      display: flex;
      margin-left: -100%;
      grid-row: 1 / 3;
      grid-column: 1 / 3;
      transition: all 0.3s ease-in-out;
      background-color: rgba(0, 0, 0, 0.9);
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 6;
    }
  }
}