.sectors-container {
  .modal-body {
    .buttons {
      gap: 8px;
    }
  }

  .gradient {
    width: 120px;
  }
}

@media screen and (min-width: 1280px) {
  .sectors-container {
    .list {
      .list-header {
        grid-template-columns: 50px 100px 1fr 130px 130px 160px;
      }

      .list-row {
        grid-template-columns: 50px 100px 1fr 130px 130px 160px;

        .buttons-list-row {
          .row {
            gap: 4px;
          }
        }
      }
    }
  }
}